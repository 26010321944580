import {domReady} from '@roots/sage/client';
//import "slick-carousel/slick/slick.min";

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);

// Import Bootstrap
import 'bootstrap';

// import external dependencies
import 'jquery';

// Import everything from autoload
//import 'autoload/**/*'

// Import Slick carousel
import 'slick-carousel/slick/slick.min';

//import fancybox
//import '@fancyapps/fancybox/dist/jquery.fancybox.min';

//import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";

//fix for google page speed: make event listeners passive to improve scrolling performance
// !function(e){"function"==typeof define&&define.amd?define(e):e()}(function(){var e,t=["scroll","wheel","touchstart","touchmove","touchenter","touchend","touchleave","mouseout","mouseleave","mouseup","mousedown","mousemove","mouseenter","mousewheel","mouseover"];if(function(){var e=!1;try{var t=Object.defineProperty({},"passive",{get:function(){e=!0}});window.addEventListener("test",null,t),window.removeEventListener("test",null,t)}catch(e){}return e}()){var n=EventTarget.prototype.addEventListener;e=n,EventTarget.prototype.addEventListener=function(n,o,r){var i,s="object"==typeof r&&null!==r,u=s?r.capture:r;(r=s?function(e){var t=Object.getOwnPropertyDescriptor(e,"passive");return t&&!0!==t.writable&&void 0===t.set?Object.assign({},e):e}(r):{}).passive=void 0!==(i=r.passive)?i:-1!==t.indexOf(n)&&!0,r.capture=void 0!==u&&u,e.call(this,n,o,r)},EventTarget.prototype.addEventListener._original=e}});

//import javascript files
import  './isotope.js';
import  './sliders.js';
import  './layout-accordion.js';
import  './countup.js';
import  './forms.js';
import  './temp.js';

// JQ start here
(function($){

  function ctaheight(){
    $('.cta1-1').each( function(){
      var height = jQuery(this).find('.foreground').outerHeight(false);
      jQuery(this).find('.background').outerHeight(height);
    })
  
    $('.cta2-2').each( function(){
      var height = jQuery(this).find('.foreground .col1').outerHeight(false);
      var height2 = jQuery(this).find('.foreground .col2').outerHeight(false);
      console.log(height);
      console.log(height2);
      jQuery(this).find('.background .col1').outerHeight(height);
      jQuery(this).find('.background .col2').outerHeight(height2);
    })
  }

  //dom ready
  $(window).ready(function(){
    ctaheight();
 
    //remove preloader after loaded
    $('.preloader').fadeOut('200');

    //Make elements equal height
    $('.matchHeight').matchHeight();
    $('.matchMinHeight').matchHeight({ property: 'min-height' });
    window.setTimeout(timeoutfunctions, 100);
    function timeoutfunctions() {
      $('.matchHeight').matchHeight();
      $('.matchMinHeight').matchHeight({ property: 'min-height' });
    }

    //only for navigation_hotel
    if($('body').hasClass('navigation_hotel')){
      //subnavigatie hoogte top bepalen 
      var heightofnav = $('header .container .row').outerHeight(false);
      $('header .subnavigation').css('top',heightofnav);

      //subnavigation event trigger
      $('.subnavigation').hide();
        
      $('.nav-primary .menu-item-has-children').hover(function(){
        var navitem = $(this).find('a.nav-link').data('idofpage');
        $('.subnavigation').each(function(){
          if( $(this).data('idofpagetoshow') == navitem ){
            $('.subnavigation[data-idofpagetoshow="'+navitem+'"]').addClass('expanded').stop().slideDown(250);
          }else{
            $(this).hide();
          }
        });
      });

      $('header').mouseleave(function() {
        //$(this).slideUp(10);
        $('.subnavigation.expanded').removeClass('expanded').slideUp(250);
      });
    }

    //header top close
    $('.remove-banner').click(function (event) {
        event.preventDefault();
        sessionStorage.setItem('visit', '1');
        $('.header-top-banner').remove();
    });
    
    Fancybox.bind(".various", {
      groupAll : true, // Group all items
      on : {
        ready : (fancybox) => {
          console.log(`fancybox #${fancybox.id} is ready!`);
        },
        closing : (fancybox) => {
          sessionStorage.setItem('visit', '1');
        },
        "Carousel.Panzoom.click" : (fancybox) => {
          sessionStorage.setItem('visit', '1');
        },
        "*": (event, fancybox, slide) => {
          const sleep = ms => new Promise(r => setTimeout(r, ms));
          console.log(`event: ${event}`);
        }
      }
    });

		$('.newspopup a').click(function(){
      sessionStorage.setItem('visit', '1');
		});

    if (!sessionStorage.getItem('visit', '1')) {
      $('.header-top-banner').removeClass('d-none').slideUp(1).delay(100).slideDown('slow')
    }

    if (!sessionStorage.getItem('visit')) {
      $('.header-top-popup').addClass('show');
      var el = document.getElementsByClassName('various');
      $(el).trigger( "click" );
    }
  })

  $(window).resize(function(){
    ctaheight();
  })

  $(window).load(function() {
    $(window).trigger('resize');
  });
  
}(jQuery));