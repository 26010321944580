(function($){


  
  $(document).on('ready',function(){
    
    //slicktracking start when slick slide of homeslider is loaded 
    $('.homeslider .slickslider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.slick-slide-tracking').removeClass('slick-active');
      setTimeout(function(){ $('.slick-slide-tracking').addClass('slick-active'); }, 10);

      //console.log(currentSlide, nextSlide);
      $('.homeslider .slickslider .slick-slide').removeClass('bigzindex');
      $('.homeslider .slickslider .slick-slide').eq(currentSlide).addClass('bigzindex');
    });
 
    //homeslider init
    var auto_slide = auto_slide();
    var slide_time = slide_time();
    var animation_type = animation_type();
    function auto_slide(){ if( $('.homeslider .slickslider').data('auto_slide') ){ return true; }else{ return false; } }
    function slide_time(){ if( $('.homeslider .slickslider').data('slide_time') ){ return $('.homeslider .slickslider').data('slide_time'); }else{ return '5000'; } }
    function animation_type(){ if($('.homeslider .slickslider').data( 'animation_type' ) == 'fade'){return true;}else{return false;} }
		$('.homeslider .slickslider').slick({
      cssEase: 'ease',
      fade: animation_type,
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: auto_slide,
      autoplaySpeed: slide_time,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover:false
    });
    
  



    //photo text image slider
    $('.slickslider-media_text').slick({
      fade: false,
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    });

    //text slider
    $('.realisatieslider').slick({
      centerMode: true,
      centerPadding: '90px',
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [    
        {
          breakpoint: 1200,
          settings: {
            centerPadding: '40px',
            slidesToShow: 2,
          },
        },{
          breakpoint: 768,
          settings: {
            centerPadding: '10px',
            slidesToShow: 1,
          },
        },
      ],
    });

    // mediaslider 
    $('section.mediaslider').each(function(){
      console.log('mediaslider found');
      var num_columns_lg = 1;
      var num_columns_md = 1;
      if( $(this).data('num_columns')) {
        num_columns_lg= $(this).data('num_columns'); 
      }
      if( $(this).data('num_columns_md')) {
        num_columns_md= $(this).data('num_columns_md'); 
      }
      var centerMode_lg, centerMode_md, variableWidth_lg, variableWidth_md;
      centerMode_lg = centerMode_md = variableWidth_lg = variableWidth_md = false;

      if(num_columns_lg == 1){
        centerMode_lg = true;
        variableWidth_lg = true;
      }
      if(num_columns_md == 1){
        centerMode_md = true;
        variableWidth_md = true;
      }
      
      console.log(num_columns_lg);
      console.log(centerMode_lg );
      console.log(variableWidth_lg );

      console.log(num_columns_md);
      console.log(centerMode_md );
      console.log(variableWidth_md);

      $(this).find('.slider').slick({
        slidesToShow: num_columns_lg,
        variableWidth: variableWidth_lg,
        centerMode: centerMode_lg,
        centerPadding: '40px',
        slidesToScroll: 1,
        arrows: true,
        responsive: [    
          {
            breakpoint: 992,
            settings: {
              slidesToShow: num_columns_md,
              variableWidth: variableWidth_md,
              centerMode: centerMode_md,
              centerPadding: '20px',
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  });
})(jQuery);


