(function($){
    $(document).ready(function(){


    //form send formidable
    // $('.formsend').on('click', function(){
    // //  $('form:first').submit();
    //       $(this).parent('form').submit();
    // })
      $('.formsend').each(function(){
        $(this).on('click',function(){
             event.preventDefault();
           $(this).parents('form').find('.frm_button_submit').trigger('click');
           //window.alert('click on button');
        })
      })
  
      //give classe to input field when field is selected
      $('.frm_pro_form .frm_form_field input , .frm_pro_form .frm_form_field textarea  ').each(function(){
        $(this).on('click',function(){
          $(this).parent().addClass('selected');
        })
        $(this).on('focus',function(){
          $(this).parent().addClass('selected');
        })
      })

    })
})(jQuery);