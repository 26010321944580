


(function($){
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};
  $(window).on('ready resize scroll', function() {
    var options = {
        useEasing: true,
        useGrouping: true,
        separator: '.',
        decimal: ',',
        prefix: '',
        suffix: '',
        duration: 3,
    };
    $('.countup-sc').each(function(){
      if ($(this).isInViewport() && ($(this).attr('data-loaded')==0)) {
        var num = $(this).attr('data-from');
        var nuen = $(this).attr('data-to');
        $(this).attr('data-loaded',1);
        if (nuen === '') {
          nuen = 0;
        }
        var count = new CountUp(this, num, nuen, 0, 3, options);
        count.start();
      } 
    })
});

})(jQuery);