console.log('temp.js loaded');
$('.homeservices .col-12 .textwrapper p:first-of-type').addClass('matchMinHeight');

// This checks if there is a class with name ".button" but not added to a a-element but to another element like a span. 
// If found, add the class of the parent to the child for styling.
$(".button a").each(function(index){
  var classList = $(this).parent().attr("class");
  $(this).addClass(classList);
  $(this).parent().removeClass(classList);

  //var classArr = classList.split(/\s+/);
  //var el = $(this);
  //var checkArr = ['light','dark','kleur1','kleur2'];
  /*checkArr.forEach((classname) => {
    if( jQuery.inArray(classname, classArr) !== -1){
      el.addClass(classname);
    }
  });*/
});


$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  var win = $(window).height();
  $('.call_to_action').each(function( index ) {
    var height = $(this).height();
    var offset = $(this).offset().top;
    var y = 0;
    y = ((100 * scroll)/(height + win)) + ((100 * (win - offset))/(height + win));
    //console.log("scroll: "+scroll + " - win: "+win + " - height: "+height + " - offset: "+offset  + " - Y:"+y);
    if (y > 100){y = 100;}
    else if (y < 0){y = 0;}
    y = (-y)*.3;
    var out = "0% " + String(y) + "%";
    var outtransform = "translate3d(0px, "+String(y)+"%, 0px)";
    $(this).find('img').css("transform", outtransform);
  });
});