(function($){
    $(document).ready(function(){


      var $eindegelijk = false;
      var $grid = $('.grid .container .row ').imagesLoaded(function () {
        console.log('isotope aanmaken ');
          $grid.isotope({
              itemSelector: '.col-12',
              masonry: {
                  columnWidth: '.col-12',
              },
          });

          var runjustonlyonce = false;
          $grid.on('arrangeComplete', function (event, filteredItems) {
              console.log('arrangeComplete');
              if (runjustonlyonce == false) {
                  runjustonlyonce = true;
              }

              if($eindegelijk==true){
                  highlightEndRow();
              }

              if (filteredItems.length == 0) {
                  console.log('show messagebox');
                  $('#messagebox').fadeIn('200');
              } else {
                  console.log('hide messagebox');
                  $('#messagebox').fadeOut('200');
              }
          }).isotope();
      });


      function highlightEndRow() {
        console.log('highleightendrow');
        var gridheight = $grid.data('isotope').size.height;
        var gridwidth = $grid.data('isotope').size.width;
        var lastelementcol1, lastelementcol2, lastelementcol3;
        var heightcol1 = gridheight,
            heightcol2 = gridheight,
            heightcol3 = gridheight;
    
        var items = $grid.data('isotope').items;
        items.forEach(function (item) {
            if (item.position.x >= (gridwidth / 3 - 10) && item.position.x <= (gridwidth / 3 * 2 - 10)) {
                heightcol2 = heightcol2 - item.size.height;
                lastelementcol2 = item;
            } else if (item.position.x >= (gridwidth / 3 * 2 - 10)) {
                heightcol3 = heightcol3 - item.size.height;
                lastelementcol3 = item;
            } else {
                heightcol1 = heightcol1 - item.size.height;
                lastelementcol1 = item;
            }
            //var changeClass = item.position.x == 640 ?
            //    'addClass' : 'removeClass';
            //$(item.element)[changeClass]('is-end-row');
        });
        if (heightcol1 != 0) {
            var calHeight = $(lastelementcol1.element).find('.imgwrapper').height() + heightcol1;
            $(lastelementcol1.element).find('.imgwrapper').height(calHeight);
        }
        if ((heightcol2 != 0) && (lastelementcol2)) {
            calHeight = $(lastelementcol2.element).find('.imgwrapper').height() + heightcol2;
            $(lastelementcol2.element).find('.imgwrapper').height(calHeight);
        }
        if ((heightcol3 != 0) && (lastelementcol3)) {
            calHeight = $(lastelementcol3.element).find('.imgwrapper').height() + heightcol3;
            $(lastelementcol3.element).find('.imgwrapper').height(calHeight);
        }
        // objectfitfallback();
      }







      // .grid is voor realisaties en voor nieuwsberichten
      $('.grid').each(function(){
        var $grid= $(this);
        $grid.imagesLoaded(function () {
          if($grid.length!=0){
            $grid.isotope({
              itemSelector: '.grid-item',
            });
            $grid.on('arrangeComplete', function (event, filteredItems) {
              if (filteredItems.length == 0) {
                $('#messagebox').fadeIn('200');
              } else {
                $('#messagebox').fadeOut('200');
              }
            }).isotope();
          }
        });
      });
        
      // bind filter button click
      $('.filters-button-group').on('click', 'button', function () {
          var filterValue = $(this).attr('data-filter');
          $(this).parents('section').find('.grid').isotope({
              filter: filterValue,
          });
          //highlightEndRow();
      });

      // change is-checked class on buttons
      $('.button-group').each(function (i, buttonGroup) {
          var $buttonGroup = $(buttonGroup);
          $buttonGroup.on('click', 'button', function () {
              $buttonGroup.find('.is-checked').removeClass('is-checked');
              $(this).addClass('is-checked');
          });
      });
        
      $(window).resize(function(){
        $('.grid').each(function(){
              $(this).isotope({
                itemSelector: '.grid-item',
                sortBy : 'original-order' ,
              });
        })
      });




    })
})(jQuery);