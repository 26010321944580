(function($){
  $(document).ready(function(){

    console.log('layout-accordion.js loaded');

    //accordion dropdown weergave
    $('section.accordion[data-displaytype="dropdown"] .accordion_item .title').click(function() {
      $(this).parent().find('.contentbig').slideToggle(150);
      $(this).toggleClass('min');
    });

    //accordion knoppen weergave
    $('section.accordion[data-displaytype="buttons"] .title').click(function() {
    var dataId = $(this).attr('data-tab');
    $(this).closest('.accordion').find('.contentbig').stop().slideUp(150);
    $(this).closest('.accordion').find('.contentbig[data-tab="'+dataId+'"]').stop().slideDown(150);

    $(this).closest('.accordion').find('.title').removeClass('min');
    $(this).addClass('min');
    });

    $('section.accordion[data-displaytype="buttons"]').each(function(){
    $(this).find('.contentbig').hide();
    $(this).find('.title:first').trigger('click');
    });


  })
})(jQuery);